<template>
    <div>
        <router-link to="/reports" class="is-pulled-right"><button class="button is-info is-small">Back to Reports Menu </button></router-link><br /><br />
        
        <div class="notification is-info" v-if="loading === true">
            <p>Loading stones ...</p>
        </div>

        <div class="notification is-warning" v-if="loading === false && filtered_stones.length == 0">
            <p>There are no stones currently marked as on hold</p>
        </div>
        
        <table class="table is-fullwidth is-striped is-size-7" v-if="loading === false && filtered_stones.length > 0">
            <thead>
                <tr>
                    <th>Barcode</th>
                    <th>Stone Type</th>
                    <th>Stone Make</th>
                    <th>Block</th>
                    <th>Length</th>
                    <th>Height</th>
                    <th>Thickness</th>
                    <th >On Hold Notes</th>
                    <th width="50"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in filtered_stones" :key="row.id">
                    <td>{{ row.barcode }}</td>
                    <td>{{ row.stone_type_name }}</td>
                    <td>{{ row.stone_make_name }}</td>
                    <td>{{ row.block }}</td>
                    <td>{{ row.length }}mm</td>
                    <td>{{ row.height }}mm</td>
                    <td>{{ row.thickness }}mm</td>
                    <td >{{ row.on_hold_notes }}</td>
                    <td><button class="button is-success is-small" @click="releaseStone(row)">Release</button></td>
                </tr>
                
            </tbody>
        </table>
    </div>

</template>

<script>
    import StoneService from '@/services/StoneService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'AddOrganisation',
        mixins: [],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    stones: []
                   
            }
        },
        methods: {
            
            getStones() {
                this.loading=true;
                StoneService.getOnHoldStones(this.accessToken)
                .then(
                    (stones => {
                    
                    this.$set(this, "stones", stones);
                    this.loading=false;
                    }).bind(this)
                );
            },
            async releaseStone(stone) {
                if(confirm("Are you sure you want to release this stone and clear the notes ?"))
                {
                
                    this.working = true
                    var formdata = { 
                        _method: 'patch',
                        on_hold : 0,
                        on_hold_notes: ''
                    }; 

                    StoneService.updateStone(this.accessToken, formdata, stone.id)
                    .then((response) => {
                        stone.on_hold = 0;
                        console.log(response)
                        
                        }).catch((error) => {
                            if(error.response.status === 422)
                            {
                                console.log("Error:" + JSON.stringify(error.response.data));
                            }
                            alert("Something went wrong. Make sure you have filled in all the fields. \nOr ring David !!")
                            this.working = false
                    });
                }
            },
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getStones()
            });
        
        },
        computed : {
            filtered_stones: function () {
                
                let filtered_stones =  this.stones.filter(function(u) {
                    if(  u.on_hold == 1) // u.artwork_new == 0 && u.artwork_repeat == 0 &&
                    {
                        return 1
                    }
                })


                return filtered_stones
            
            },
            
        },
    }
</script>