<template>
    <div>
        <router-link to="/reports" class="is-pulled-right"><button class="button is-info is-small">Back to Reports Menu </button></router-link><br /><br />
        
        <div class="notification is-info" v-if="loading === true">
            <p>Loading stones ...</p>
        </div>

        <div class="notification is-warning" v-if="loading === false && filtered_stones.length == 0">
            <p>There are no stones in the database that are free</p>
        </div>
        
        <table class="table is-fullwidth is-striped is-size-7" v-if="loading === false && filtered_stones.length > 0">
            <thead>
                <tr>
                    <th>Barcode</th>
                    <th>Stone Type</th>
                    <th>Stone Make</th>
                    <th>Block</th>
                    <th>Length</th>
                    <th>Height</th>
                    <th>Thickness</th>
                    <th >On Hold</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in filtered_stones" :key="row.id">
                    <td>{{ row.barcode }}</td>
                    <td>{{ row.stone_type_name }}</td>
                    <td>{{ row.stone_make_name }}</td>
                    <td>{{ row.block }}</td>
                    <td>{{ row.length }}mm</td>
                    <td>{{ row.height }}mm</td>
                    <td>{{ row.thickness }}mm</td>
                    <td ><span class="tag is-danger" v-if="row.on_hold == 1">On Hold</span> {{ row.on_hold_notes }}</td>
                    
                </tr>
                
            </tbody>
        </table>
    </div>

</template>

<script>
    import StoneService from '@/services/StoneService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'AddOrganisation',
        mixins: [],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    stones: []
                   
            }
        },
        methods: {
            
            getStones() {
                this.loading=true;
                StoneService.getAvailableStones(this.accessToken)
                .then(
                    (stones => {
                    
                    this.$set(this, "stones", stones);
                    this.loading=false;
                    }).bind(this)
                );
            },
            
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getStones()
            });
        
        },
        computed : {
            filtered_stones: function () {
                
                let filtered_stones =  this.stones.filter(function(u) {
                    if(  u.in_use == 0) // u.artwork_new == 0 && u.artwork_repeat == 0 &&
                    {
                        return 1
                    }
                })


                return filtered_stones
            
            },
            
        },
    }
</script>