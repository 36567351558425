<template>
    <div class="home mt-5">
      <div class="columns buttons are-large">
          <div class="column is-half ">
            <router-link to="/reports/batches-report"><button class="button is-info is-fullwidth">Batches</button></router-link>
          </div>
          <div class="column is-half">
            <router-link to="/reports/on-hold-report"><button class="button is-info is-fullwidth">Stones On Hold</button></router-link>
          </div>
          <div class="column is-half">
            <router-link to="/reports/stock-take-report"><button class="button is-info is-fullwidth">Stock Take (Stones)</button></router-link>
          </div>
          <div class="column is-half">
            <router-link to="/reports/stock-take-makes-report"><button class="button is-info is-fullwidth">Stock Take (Makes)</button></router-link>
          </div>
        </div>
      
    </div>
  </template>
  
  <script>
  
  export default {
    name: 'Home',
    components: {
      
    }
  }
  </script>
  