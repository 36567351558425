<template>
    <div>
        <router-link to="/reports" class="is-pulled-right"><button class="button is-info is-small">Back to Reports Menu </button></router-link><br /><br />
        
        <div class="notification is-info" v-if="loading === true">
            <p>Loading stone makes ...</p>
        </div>

        <div class="notification is-warning" v-if="loading === false && stone_makes.length == 0">
            <p>There are no stone makes in the database</p>
        </div>
        
        <table class="table is-fullwidth is-striped" v-if="loading === false && stone_makes.length > 0">
            <thead>
                <tr>
                    <th>Stone Make</th>
                    <th>Stone Type</th>
                    <th>Available</th>
                    <th>Assigned</th>
                    <th>On Hold</th>
                    
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in stone_makes" :key="row.id">
                    <td>{{ row.name }}</td>
                    <td>{{ row.stone_type_name }}</td>
                    <td><span @click="showStonesModal(row.available_stones)" ><u style="cursor:pointer">{{ row.available_stones.length  }}</u></span></td>
                    <td><span @click="showStonesModal(row.assigned_stones)" ><u style="cursor:pointer">{{ row.assigned_stones.length  }}</u></span></td>
                    <td><span @click="showStonesModal(row.on_hold_stones)" ><u style="cursor:pointer">{{ row.on_hold_stones.length  }}</u></span></td>
                </tr>
                
            </tbody>
        </table>

        <div class="modal" :class="{'is-active': showModal == true}">
            <div class="modal-background"></div>
                <div class="modal-content">
                    <!-- Any other Bulma elements you want -->
                    <div class="card">
                    <div class="card-content">
                        <div class="content">
                            <table class="table is-striped">
                                <thead>
                                    <th>Barcode</th>
                                    <th>On Hold</th>
                                    <th>Job</th>
                                </thead>
                                <tbody>
                                    <tr v-for="row in modal_stones" :key="row.id">
                                        <td>{{ row.barcode }}</td>
                                        <th>{{ row.on_hold_notes }}</th>
                                        <th>{{ row.job_name }}</th>
                                    </tr>

                                </tbody>
                            </table>
                        </div>
                    </div>
                    </div>
                </div>
            <button class="modal-close is-large" aria-label="close" @click="hideModal()"></button>
        </div>
    </div>

</template>

<script>
    import StoneService from '@/services/StoneService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'StockTakeMakesReport',
        mixins: [],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    stone_makes: [],
                    showModal: false,
                    modal_stones: []
                   
            }
        },
        methods: {
            
            getStoneMakes() {
                this.loading=true;
                StoneService.getStoneMakes(this.accessToken)
                .then(
                    (stone_makes => {
                    
                    this.$set(this, "stone_makes", stone_makes);
                    this.loading=false;
                    }).bind(this)
                );
            },
            showStonesModal(stones)
            {
                if(stones.length > 0)
                {
                this.modal_stones = stones;
                this.showModal = true;
                }
            },
            hideModal() {
                this.showModal = false;
            }
            
            
        },
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getStoneMakes()
            });
        
        },
        computed : {
            
            
        },
    }
</script>