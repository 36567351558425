<template>
    <div>
        <div class="is-pulled-left">
            <input type="text" class="input" v-model="search" placeholder="Search ..."/>
        </div>
        <div class="is-pulled-right">
            <router-link to="/reports" class="is-pulled-right"><button class="button is-info is-small">Back to Reports Menu </button></router-link><br /><br />
        </div>

        <div class="notification is-info" v-if="loading === true">
            <p>Loading batches ...</p>
        </div>

        <div class="notification is-warning" v-if="loading === false && filtered_batches.length == 0" style="clear:both">
            <p >There are no stone batches to display</p>
        </div>
        
        <table class="table is-fullwidth is-striped" v-if="loading === false && filtered_batches.length > 0">
            <thead>
                <tr>
                    <th :class="sortClass('stone_types.name')" @click="updateOrderBy('stone_types.name')" class="pointer">Stone Type</th>
                    <th :class="sortClass('stone_makes.name')" @click="updateOrderBy('stone_makes.name')" class="pointer">Stone Make</th>
                    <th>Block</th>
                    <th>Length</th>
                    <th>Height</th>
                    <th>Thickness</th>
                    <th >Qty</th>
                    <th >Available</th>
                    <th width="50"></th>
                </tr>
            </thead>
            <tbody>
                <tr v-for="row in filtered_batches" :key="row.id">
                    <td>{{ row.stone_type_name }}</td>
                    <td>{{ row.stone_make_name }}</td>
                    <td>{{ row.block }}</td>
                    <td>{{ row.length }}mm</td>
                    <td>{{ row.height }}mm</td>
                    <td>{{ row.thickness }}mm</td>
                    <td >{{ row.qty }}</td>
                    <td >{{ row.qty - row.in_use }}</td>
                    <td><router-link :to="'/batches/' + row.id + '?report_view=1'"><button class="button is-link is-small">View</button></router-link></td>
                </tr>
                
            </tbody>
        </table>
    </div>

</template>

<style scoped>
table thead th.pointer {cursor:pointer }
table thead th.sorted_asc {text-decoration:underline }
table thead th.sorted_desc {text-decoration:underline dotted }
</style>

<script>
    import StoneService from '@/services/StoneService.js';
    //import UtilityService from '@/services/UtilityService.js';
    
    export default {
      name: 'BatchesReport',
        mixins: [],
        data: function () {
            return {
                   loading : false,
                  search: "",
                    API_NAME: process.env.VUE_APP_API_NAME,
                    accessToken: '',
                    batches: [],
                    order_by : '',
                    order_by_direction: ''
                   
            }
        },
        methods: {
            
            getBatches() {
                this.loading=true;
                StoneService.getBatches(this.accessToken, this.order_by + " " + this.order_by_direction)
                .then(
                    (batches => {
                    
                    this.$set(this, "batches", batches);
                    this.loading=false;
                    }).bind(this)
                );
            },
            notWorking() {
                alert('Not yet working')
            },
            updateOrderBy(column) {
                this.order_by = column

                if(this.order_by_direction == "ASC")
                {
                    this.order_by_direction = "DESC"
                }
                else{
                    this.order_by_direction = "ASC"
                }

                this.getBatches();

                localStorage.batches_report_order_by = this.order_by
                localStorage.batches_report_order_by_direction = this.order_by_direction
            },
            sortClass(column) {
                return {
                    'sorted_desc' : this.order_by == column && this.order_by_direction == "DESC",
                    'sorted_asc' : this.order_by == column && this.order_by_direction == "ASC",
                }
            }
            
        },
         
        created() {
            this.getAccessToken()
            .then(() => { 
                this.getBatches()
            });

        },
        mounted() {
            if (localStorage.batches_report_order_by) {
                this.order_by = localStorage.batches_report_order_by;

                if(this.order_by == "stones.id")
                {
                    this.order_by = "batches.id"
                }
            }
            else{
                this.order_by = 'stone_makes.name'
            }

            if (localStorage.batches_report_order_by_direction) {
                this.order_by_direction = localStorage.batches_report_order_by_direction;
            }
            else{
                this.order_by_direction = 'ASC'
            }
        },
        computed : {
            filtered_batches: function () {
                let batch_search = this.search

                let filtered_batches =  this.batches.filter(function(u) {
                    if(  u.active == 1) // u.artwork_new == 0 && u.artwork_repeat == 0 &&
                    {
                        return 1
                    }
                })

                if(batch_search != '' && batch_search != null)
                {
                    filtered_batches = filtered_batches.filter(row => row.stone_make_name.toLowerCase().includes(batch_search.toLowerCase()) || 
                        ( row.stone_type_name != null && row.stone_type_name.toLowerCase().includes(batch_search.toLowerCase()) ) || 
                        ( row["length"] != null && row["length"].toString().toLowerCase().includes(batch_search.toLowerCase())  ) || 
                        ( row["height"] != null && row["height"].toString().toLowerCase().includes(batch_search.toLowerCase())  )  ||
                        ( row.thickness != null && row.thickness.toString().toLowerCase().includes(batch_search.toLowerCase())  )  
                    )
                }

                return filtered_batches
            
            },
            
        },
        
        
    }
</script>